import type { FormatterMap, ProductRoutesOptions, RouteList } from '@grandvisionhq/frk-routing'
import { createSimpleFormatter } from '@grandvisionhq/frk-routing'
import {
  createAuthFormatters,
  createCheckoutFormatters,
  createMyAccountFormatters,
  productDetailFormatters,
  productListFormatter,
} from '@grandvisionhq/www-next/formatters'

const productRoutesOptions: ProductRoutesOptions = {
  'contact-lenses': 'contactlenzen',
  frames: 'brillen',
  solutions: 'contactlenzen/lenzenvloeistof',
  sunglasses: 'zonnebrillen',
  'ready-readers': 'brillen/kant-en-klare-leesbrillen',
}

export const localRouteFormatters: FormatterMap = {
  home: createSimpleFormatter('/'),

  'cart.overview': createSimpleFormatter('cart'),

  'appointments.book': createSimpleFormatter('afspraak-maken'),

  'express-checkout': createSimpleFormatter('express-checkout'),
  'order.confirm': createSimpleFormatter('order/confirm'),
  ...createMyAccountFormatters(),
  ...createAuthFormatters(),
  ...createCheckoutFormatters({
    path: 'checkout',
    children: { login: 'login', shipping: 'shipping', overview: 'overview', payment: 'payment' },
  }),
  ...productListFormatter(productRoutesOptions),
  ...productDetailFormatters(productRoutesOptions),
  wishlist: createSimpleFormatter('/wishlist'),
}

export const routes: RouteList = [
  ['/', 'home'],

  ['/checkout/login', 'checkout.login'],
  ['/checkout/shipping', 'checkout.shipping'],
  ['/checkout/overview', 'checkout.overview'],
  ['/checkout/payment', 'checkout.payment'],
  ['/api/payment-callback', 'payment.callback'],
  ['/order/confirm', 'order.confirm'],

  ['/book-appointment/(.*)?', 'appointments.book'],
  ['/reschedule-appointment', 'appointments.reschedule'],
  ['/cancel-appointment', 'appointments.cancel'],
]
